class BannerItem extends BaseItem {
    constructor(data) {
        super(data);
        this.classList.add(
            // 'grandangolo-url'
            'md:rounded-lg', 'cursor-pointer', 'zoom-on-hover',
            'flex', 'relative', 'bg-black',
            'h-full', 'text-white', 'text-5xl'
        );
        // style = "font-family: Engravers;"
    }

    connectedCallback() {
        if (!this.data) return;
        // Setting font
        this.style.fontFamily = this.data.font || "Oswald";
        this.style.fontSize = window.innerWidth > 768 ? '38px' : '25px';
        /* <image-container class = "absolute flex h-full w-full">
            <img class = "grandangolo-image darken-more banner-image transition-all object-cover w-full brightness-[0.35] blur-[1px]" src = "https://www.byoblu.com/wp-content/uploads/2023/03/banner-pangea-1270x371-1.jpg">
        </image-container>
        <news-title class = "grandangolo-title z-10 text-white p-5 text-2xl md:text-5xl uppercase" style = "font-family: Oswald">
            Guarda l'ultimo episodio di Grandangolo
        </news-title>
        <news-record class = "z-10 text-white p-5 absolute bottom-0 right-0 opacity-75 md:opacity-100 text-sm md:text-xl" style = "font-family: Oswald">
            GRANDANGOLO – PANGEA
        </news-record> */
        // Creating image
        const imageContainer = document.createElement('image-container');
        imageContainer.classList.add('absolute', 'flex', 'h-full', 'w-full');
        const image = document.createElement('img');
        image.classList.add('banner-image', 'transition-all', 'object-cover', 'w-full');
        // Adjusting brightness & blur
        if (this.data.brightness || this.data.blur) image.style.filter = `brightness(${this.data.brightness}) blur(${this.data.blur}px)`;
        image.src = this.data.image;
        imageContainer.append(image);
        this.append(imageContainer);

        // Title
        const newsTitle = document.createElement('news-title');
        newsTitle.classList.add('z-10', 'text-white', 'p-5'); // ), 'text-2xl', 'md:text-5xl');
        if (this.data.font == 'Raleway') {
            newsTitle.classList.add('text-xl', 'md:text-3xl')
        }
        newsTitle.textContent = this.data.title;
        this.append(newsTitle);

        // Description
        const newsDescription = document.createElement('news-record');
        newsDescription.classList.add('z-10', 'text-white', 'p-5', 'absolute', 'bottom-1', 'right-0', 'opacity-75'); // 'text-2xl', 'md:text-5xl');
        if (this.data.font == 'Raleway') {
            newsDescription.classList.add('text-xl', 'md:text-3xl')
        }
        newsDescription.textContent = this.data.subtitle;
        this.append(newsDescription);
    }
};


window.customElements.define('banner-item', BannerItem);